<template>
  <b-form class="rule-form">
    <validation-observer
      ref="createForm"
      v-slot="{ handleSubmit }"
      class="mr-4 ml-4"
    >
      <div class="text-center pb-2"><h3>{{ getStageName }}</h3></div>
      <b-form v-if="stageType !== 3" @submit.stop.prevent="handleSubmit()">
        <validation-provider
          name="Max Heart"
          v-slot="validationContext"
          :rules="{ required: true, regex: '^([0-9]+)$' }"
        >
          <b-row class="pb-2">
            <b-col sm="4">
              <label>Max Heart</label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-model.number="model.LISTENING"
                name="max-heart"
                type="number"
                :state="getValidationState(validationContext)"
                placeholder="2"
                aria-describedby="input-category-name-feedback"
              />
            </b-col>
          </b-row>
          <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </validation-provider>
      </b-form>

      <b-form v-else @submit.stop.prevent="handleSubmit()">
        <template v-for="(value, index) in model.VOCAB">
          <div :key="index" class="pb-2">
            <h4 class="game-title">GAME {{ parseInt(index) + 1 }}</h4>
            <validation-provider
              :name="`vocab-${index}-max-heart`"
              v-slot="validationContext"
              :rules="{ required: true, regex: '^([0-9]+)$' }"
            >
              <b-row class="pb-2">
                <b-col sm="4">
                  <label>Max Heart</label>
                </b-col>
                <b-col sm="6">
                  <b-form-input
                    v-model.number="model.VOCAB[index]"
                    :name="`vocab-${index}-max-heart`"
                    type="text"
                    :state="getValidationState(validationContext)"
                    placeholder="2"
                  />
                </b-col>
              </b-row>
              <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>
        </template>
      </b-form>
      <div class="text-center my-2">
        <b-button type="button" variant="primary" @click="onSave">
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span class="align-middle">Save</span>
        </b-button>
      </div>
    </validation-observer>
  </b-form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'

const STAGE_NAME = [
  'CHALLENGE',
  'LISTENING',
  'VOCAB'
]

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  props: {
    maxHeartPerStage: {
      type: Object,
      default: () => {}
    },

    stageType: {
      type: Number,
      default: 0
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      model: '',
      STAGE_NAME
    }
  },

  computed: {
    getStageName() {
      return STAGE_NAME[this.stageType - 1]
    }
  },

  watch: {
    maxHeartPerStage() {
      this.getModel()
    },

    stageType() {
      this.getModel()
    }
  },

  methods: {
    getModel() {
      if (this.maxHeartPerStage[this.getStageName]) {
        this.model = {}
        this.model[this.getStageName] = this.maxHeartPerStage[this.getStageName]
      }
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation){
        this.$emit('save', this.model)
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  }
}
</script>
<style lang="scss" scoped>
.rule-form {
  margin: 0 200px !important;
  label {
    font-size: 16px;
  }
  .game-title {
    margin-left: -150px;
  }
}
</style>
