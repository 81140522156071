import api from '@/libs/axios'

export default {
  async getDetail(params) {
    let response = await api.get('/admin/configs/detail', { params })
    return response
  },
  async create(payload) {
    let response = await api.post('/admin/configs', payload)
    return response
  },
  async update(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
}
