export const STAR_RATING_RULES_DEFAULT= {
  CHALLENGE: {
      ONE_STAR: 50,
      TWO_STAR: 65,
      THREE_STAR: 80
  },
  LISTENING: {
      ONE_STAR: 50,
      TWO_STAR: 70
  },
  VOCAB: [
      {
          ONE_STAR: 50,
          TWO_STAR: 70
      },
      {
          ONE_STAR: 50,
          TWO_STAR: 70
      },
      {
          ONE_STAR: 50,
          TWO_STAR: 70
      }
  ]
}

export const MAX_HEAR_PER_STAGE_DEFAULT = {
  LISTENING:  2,
  VOCAB: [2,2,2]
}

export const VOCAB_COUNTDOWN_TIME_DEFAULT = {
  GAME_1: 6,
  GAME_2: 7,
  GAME_3: 8
}

export default {}